<template>
  <v-card flat>
    <v-skeleton-loader v-if="loadingFilters"
                       type="article, article, article"/>
    <v-card-text v-if="!loadingFilters">
      <v-select
          id="orders_filters_customer_input"
          v-model="customerFilter"
          :items="customers"
          :label="$t('customer')"
          item-text="name"
          item-value="id"
          hide-details="auto"
          class="mb-3"
      ></v-select>
      <v-select
          id="orders_filters_order_type_input"
          v-model="orderTypeFilter"
          :items="orderTypes"
          :label="$t('order_type')"
          item-text="name"
          item-value="id"
          hide-details="auto"
          class="mb-3"
      ></v-select>
      <v-select
          id="orders_filters_courier_input"
          v-model="courierFilter"
          :items="couriers"
          :label="$t('courier')"
          item-text="name"
          item-value="id"
          hide-details="auto"
          class="mb-3"
      ></v-select>
      <v-select
          id="orders_filters_sort_input"
          v-model="sort"
          :items="sorts"
          :label="$t('sort')"
          item-text="name"
          item-value="id"
          hide-details="auto"
          class="mb-3"
      ></v-select>
    </v-card-text>
    <div class="ml-3">
      <v-spacer></v-spacer>
      <v-btn v-if="!loadingFilters"
          @click="clear"
          id="orders_filters_clear_btn"
          color="accent"
          class="mr-1">
        {{ $t('clear') }}
      </v-btn>
      <v-btn @click="$emit('close')"
             id="orders_filters_cancel_btn"
             color="primary">
        {{ $t('cancel') }}
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn v-if="!loadingFilters"
          @click="applyFilters"
          id="orders_filters_submit_btn"
          color="secondary"
          class="mt-3">
        {{ $t('filter') }}
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import {
  ORDER_TYPE_FILTER_PRE_PACKED,
  ORDER_TYPE_FILTER_SINGLES,
} from '@/bootstrap/config'
import {
  SORT_CUSTOMER,
  SORT_LOCATION,
  SORT_ORDER_TYPE,
  SORT_PRODUCT,
  getSorts,
} from '@/utilities/ordersSortOptions'

export default {
  data: () => ({
    loadingFilters: true,
    customerFilter: null,
    orderTypeFilter: null,
    courierFilter: null,
    sort: null,
  }),
  created () {
    this.getFilters()
    this.customerFilter = this.$store.state.orders.customerFilter
    this.orderTypeFilter = this.$store.state.orders.orderTypeFilter
    this.courierFilter = this.$store.state.orders.courierFilter
    this.sort = this.$store.state.orders.sort
  },
  computed: {
    sorts () {
      let filteredSorts = getSorts()
      if (this.orderTypeFilter !== null) {
        filteredSorts = filteredSorts.filter(item => item.type !== SORT_ORDER_TYPE)
      }
      if (this.customerFilter !== null) {
        filteredSorts = filteredSorts.filter(item => item.type !== SORT_CUSTOMER)
      }
      if (this.orderTypeFilter !== ORDER_TYPE_FILTER_SINGLES) {
        filteredSorts = filteredSorts.filter(item => item.type !== SORT_PRODUCT)
      }
      if (
          this.orderTypeFilter !== ORDER_TYPE_FILTER_SINGLES &&
          this.orderTypeFilter !== ORDER_TYPE_FILTER_PRE_PACKED
      ) {
        filteredSorts = filteredSorts.filter(item => item.type !== SORT_LOCATION)
      }
      return filteredSorts
    },
    customers () {
      const filters = this.$store.state.orders.customerFilters
      filters.unshift({id: null, name: this.$t('all_customers')})
      return filters
    },
    orderTypes () {
      const filters = this.$store.state.orders.orderTypeFilters
      filters.unshift({id: null, name: this.$t('all_order_types')})
      return filters
    },
    couriers () {
      const filters = this.$store.state.orders.courierFilters
      filters.unshift({id: null, name: this.$t('all_couriers')})
      return filters
    },
  },
  methods: {
    async getFilters () {
      this.loadingFilters = true
      await this.$store.dispatch('orders/getOrdersFilters')
      this.loadingFilters = false
    },
    clear () {
      this.customerFilter = null
      this.orderTypeFilter = null
      this.courierFilter = null
      this.sort = null
    },
    validateCurrentSort () {
      if (!this.sorts.find(item => item.id === this.sort)) {
        this.sort = null
      }
    },
    applyFilters () {
      this.$store.commit('orders/setCustomerFilter', this.customerFilter)
      this.$store.commit('orders/setCourierFilter', this.courierFilter)
      this.$store.commit('orders/setOrderTypeFilter', this.orderTypeFilter)
      this.validateCurrentSort()
      this.$store.commit('orders/setSort', this.sort)
      this.$store.commit('orders/setPage', 1)
      this.$emit('change')
      this.$emit('close')
    }
  }
}
</script>
