import Vue from 'vue'
import Vuex from 'vuex'
import core from '../store/core'
import orders from '../store/orders'
import picking from '../store/picking'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)
const store = new Vuex.Store({
        modules: {
            core: core,
            orders: orders,
            picking: picking
        },
        plugins: [
            createPersistedState()
        ]
    }
)

export default store