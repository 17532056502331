import setState from "@/utilities/store"
import {api} from "@/bootstrap/api"
import mapOrder from "@/utilities/mapOrder"
import {getSorts} from "@/utilities/ordersSortOptions"
import i18n from '@/plugins/i18n';

const defaultState = () => ({
    customerFilter: null,
    orderTypeFilter: null,
    courierFilter: null,
    sort: null,
    startPickFromBarcode: [],
    customerFilters: [],
    courierFilters: [],
    orderTypeFilters: [],
    orders: [],
    totalFiltered: 0,
    total: 0,
    page: 1,
    perPage: 25
})

export default {
    state: defaultState(),
    getters: {
        getSortName (state) {
            if (state.sort) {
                return getSorts().find(item => item.id === state.sort).name
            }
            return null
        },
        getFilterAndSortParams (state) {
            const params = {};
            if (state.customerFilter) {
                params.filter_huboo_box = state.customerFilter
            }
            if (state.orderTypeFilter) {
                params.filter_work_group = state.orderTypeFilter
            }
            if (state.courierFilter) {
                params.filter_carrier = state.courierFilter
            }
            if (state.sort) {
                const sort = getSorts().find(item => item.id === state.sort)
                params[sort.type] = sort.value
            }
            return params;
        }
    },
    mutations: {
        setPage (state, page) {
            state.page = page
        },
        setPerPage (state, perPage) {
            state.perPage = perPage
        },
        setOrders (state, orders) {
            state.orders = orders.map(mapOrder)
        },
        setTotalFiltered (state, total) {
            state.totalFiltered = total
        },
        setTotal (state, total) {
            state.total = total
        },
        setCustomerFilters (state, filters) {
            state.customerFilters = filters
        },
        setCourierFilters (state, filters) {
            state.courierFilters = filters
        },
        setOrderTypeFilters (state, filters) {
            state.orderTypeFilters = filters
        },
        setCustomerFilter (state, filter) {
            state.customerFilter = filter
        },
        setCourierFilter (state, filter) {
            state.courierFilter = filter
        },
        setOrderTypeFilter (state, filter) {
            state.orderTypeFilter = filter
        },
        setSort (state, sort) {
            state.sort = sort
        },
        resetState (state) {
            setState(state, defaultState())
        },
        setStartPickFromBarcode (state, startPickFromBarcode) {
            state.startPickFromBarcode = startPickFromBarcode
        },
    },
    actions: {
        async startNextOrder ({commit, getters, rootState, dispatch}) {
            const params = getters.getFilterAndSortParams;
            params.hub_id = rootState.core.hubId;
            try {
                const results = await api('nextOrder', {params: params, handleError: false})
                dispatch('core/redirect', {name: 'order', params: {orderId: results.data.id}}, {root: true})
            } catch (e) {
                if (e?.response?.status === 404) {
                    commit('core/addError', {
                        message: i18n.t('packing.all_remaining_orders_in_progress'),
                        timeout: 8000
                    }, {root: true})
                } else {
                    dispatch('core/handleError', e, {root: true});
                }
                dispatch('core/redirect', {name: 'orders'}, {root: true})
            }
        },
        async getOrders ({state, commit, getters, rootState}) {
            const params = getters.getFilterAndSortParams;
            params.hub_id = rootState.core.hubId;
            params.page = state.page;
            const results = await api('allocatedOrders', {params: params})
            commit('setOrders', results.data.data)
            commit('setTotalFiltered', results.data.total)
            commit('setPerPage', results.data.per_page)
        },
        async getOrdersOverview ({commit, rootState}) {
            const results = await api(
                'ordersOverview',
                {
                    params: {
                        hub_id: rootState.core.hubId
                    }
                }
            )
            commit('setTotal', results.data.total_orders)
            commit('setStartPickFromBarcode', results.data.start_pick_from_barcode)
        },
        async getOrdersFilters ({commit, rootState}) {
            const results = await api(
                'ordersFilters',
                {
                    params: {
                        hub_id: rootState.core.hubId
                    }
                }
            )
            commit('setCustomerFilters', results.data.huboo_box)
            commit('setCourierFilters', results.data.carrier)
            commit('setOrderTypeFilters', results.data.work_group)
        }
    },
    namespaced: true
}
