import Login from '@/components/pages/Login'
import HubSelector from '@/components/pages/HubSelector'
import Home from '@/components/pages/Home'
import Orders from '@/components/pages/Orders'
import Picking from '@/components/pages/Picking'
import i18n from '@/plugins/i18n'
import store from '@/bootstrap/store'
import Settings from "@/components/pages/Settings";
import StartPickFromBarcode from '@/components/pages/StartPickFromBarcode'
import StartNextOrder from "../components/pages/StartNextOrder";

export default [
    {
        name: 'login',
        path: '/login',
        component: Login,
        meta: {
            title() {
                return i18n.t('login')
            }
        }
    },
    {
        name: 'hub_selector',
        path: '/select-hub',
        component: HubSelector,
        meta: {
            title() {
                return i18n.t('select_site_hub')
            }
        }
    },
    {
        name: 'home',
        path: '/',
        component: Home,
        meta: {
            title() {
                return i18n.t('home')
            }
        }
    },
    {
        name: 'orders',
        path: '/orders',
        component: Orders,
        meta: {
            title() {
                return i18n.t('orders_hub_id', { hub_id: store.state.core.hubId })
            }
        }
    },
    {
        name: 'order',
        path: '/order/:orderId',
        component: Picking,
        props: true,
        meta: {
            title() {
                return i18n.t('order')
            }
        }
    },
    {
        name: 'start-next-order',
        path: '/start-next-order',
        component: StartNextOrder,
        props: false,
        meta: {
            title() {
                return i18n.t('loading_next_order')
            }
        }
    },
    {
        name: 'start-pick-with-barcode',
        path: '/hub/:hub_id/start_pick_pick_with_barcode',
        component: StartPickFromBarcode,
        meta: {
            title: function() {
                return i18n.t('start_pick_with_barcode_title', {hub_id: store.state.core.hubId})
            }
        }
    },
    {
        name: 'settings',
        path: '/settings',
        component: Settings,
        props: true,
        meta: {
            title () {
                return i18n.t('settings')
            }
        }
    }
]
