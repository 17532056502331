<template>
  <v-app v-bind:style="{zoom: zoom}">
    <v-app-bar app color="primary" dark>
      <v-app-bar-nav-icon v-if="navigationEnabled" @click.stop="drawer = !drawer"/>
      <img class="mr-3" :src="require('./assets/logo-os.svg')" height="30"/>
      <v-toolbar-title class="pl-0">
        {{ title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-navigation-drawer v-if="navigationEnabled" v-model="drawer" app left temporary height="100%">
      <navigation/>
      <template v-slot:append>
        <v-list-item-group active-class="primary--text">
          <v-list-item disabled>
            <v-list-item-content>
              <v-list-item-title id="version">
                {{ `${$t('version')} ${version}` }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </template>
    </v-navigation-drawer>
    <v-main>
      <v-container>
        <router-view></router-view>
      </v-container>
    </v-main>
    <errors/>
  </v-app>
</template>

<script>
import Navigation from '@/components/navigation/Default'
import Errors from '@/components/generic/Errors'
import auditLog from "@/bootstrap/auditLog";
import {isHidden, getVisibilityEvent} from "@/utilities/global";
import {SESSION_ACTIVE_EVENTS} from "@/bootstrap/config";
import {version} from '@/../package'

export default {
  name: 'App',
  components: {
    'navigation': Navigation,
    'errors': Errors
  },
  data: () => ({
    drawer: false,
    visibilityEvents: {
      hidden: "visibilitychange",
      webkitHidden: "webkitvisibilitychange",
      mozHidden: "mozvisibilitychange",
      msHidden: "msvisibilitychange"
    },
    zoom: 1,
    version
  }),
  computed: {
    title() {
      return this.$store.getters['core/getTitle']
    },
    navigationEnabled() {
      return this.$store.getters['core/isAuthenticated']
    },
  },
  onIdle() {
    this.$store.dispatch('core/logout')
  },
  created() {
    for (const key in SESSION_ACTIVE_EVENTS) {
      window.addEventListener(SESSION_ACTIVE_EVENTS[key], this.setActiveSessionTime)
    }
    this.checkActiveSession()
    if (getVisibilityEvent()) {
      document.addEventListener(getVisibilityEvent(), this.checkActiveSession)
    }
    window.addEventListener('beforeunload', this.commitAuditLog)
    if (this.$cookies) {
      this.zoom = this.$cookies.get('zoom');
      window.bus.$on('zoomChanged', (zoom) => {
        this.$cookies.set('zoom', zoom);
        this.zoom = zoom;
      });
    }
  },
  beforeDestroy() {
    for (const key in SESSION_ACTIVE_EVENTS) {
      window.removeEventListener(SESSION_ACTIVE_EVENTS[key], this.setActiveSessionTime)
    }
    document.removeEventListener(getVisibilityEvent(), this.checkActiveSession)
    window.removeEventListener('beforeunload', this.commitAuditLog);
  },
  methods: {
    setActiveSessionTime: function () {
      this.checkActiveSession()
      this.$store.commit('core/setActiveSessionTime');
    },
    commitAuditLog() {
      auditLog.commit()
    },
    checkActiveSession() {
      if (!isHidden()) {
        this.$store.dispatch('core/checkActiveSession')
      }
    }
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.container {
  @media #{map-get($display-breakpoints, 'md-and-up')}{
    max-width: #{map-get($grid-breakpoints, 'md')} !important;
  }
}

.v-card__title {
  word-break: break-word !important;
}
</style>
