<template>
  <div>
    <div class="d-sm-flex justify-space-between align-center">
      <div class="mb-3 mr-3" v-if="appliedFilters">
        <caption>
          {{ $t('filters_sort') }}
        </caption>
        <p class="ma-0 font-weight-bold">
          {{ appliedFilters }}
        </p>
      </div>
      <div class="row align-center">
        <div class="col-6 col-sm-4 mb-3">
          <caption>
            {{ $t('showing') }}
          </caption>
          <p class="ma-0 font-weight-bold" v-if="!loadingOrders">
            {{ orders.length }}/{{ totalFiltered }}
          </p>
        </div>
        <div class="col-6 col-sm-4 mb-3">
          <caption>
            {{ $t('remaining') }}
          </caption>
          <p class="ma-0 font-weight-bold" v-if="!loadingOverview">
            {{ total }}
          </p>
        </div>
        <div class="col-6 col-sm-4 mb-3">
          <v-btn class="mb-3"
                 id="orders_filters_drawer_open_btn"
                 @click.stop="filtersVisible = true"
                 :disabled="loadingOverview"
                 :loading="loadingOverview"
          >
            <v-icon left>
              mdi-filter-outline
            </v-icon>
            {{ $t('filters') }}
          </v-btn>
        </div>
        <div v-if="startPickFromBarcodeEnabled" class="col-12 col-sm-4 mb-3 pt-0">
          <v-btn class="secondary mb-3"
                 id="start_pick_from_barcode_btn"
                 @click="goToStartPickFromBarcode()"
          >
            <span class="text-wrap">
              {{ $t('start_pick_with_barcode') }}
            </span>
          </v-btn>
        </div>
      </div>
    </div>
    <v-divider class="mb-3"/>
    <template v-if="!loadingOrders">
      <v-card
          class="mb-3"
          :class="displayClass(order)"
          v-for="order in orders"
          :id="'order_' + order.id"
          :key="order.id"
      >
        <v-card-text>
          <div class="d-sm-flex justify-space-between align-center">
            <div class="d-flex mr-3 mb-3 mb-sm-0 align-center">
              <div class="mr-3">
                <p class="text--secondary mb-0 mr-3">
                  {{ $t('shipment_id') }}
                </p>
                <p class="display-1 text--primary ma-0">
                  {{ order.shipmentId }}
                </p>
              </div>
              <div>
                <div v-for="(value, name) in order.attributes" :key="name" class="float-left">
                  <p class="text--secondary mb-0 mr-3">
                    {{ name }}:
                    <span class="text--primary font-weight-bold">
                      {{ value }}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <v-spacer/>
            <div class="align-self-stretch">
              <v-btn :loading="order.id === loadingOrderId"
                     @click="startOrder(order.id, order.customer)"
                     class="pick-button"
                     block
              >
                <v-icon v-if="order.taken || orderCustomerHasStartPickFromBarcodeEnabled(order.customer)" color="amber">mdi-alert-rhombus-outline</v-icon>
                <v-icon v-if="order.splitPack" color="amber">mdi-package-variant</v-icon>
                <span v-if="order.splitPack">{{ $t('wms') }}</span>
                <span v-else>{{ $t('pick') }}</span>
              </v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
      <start-pick-modal v-if="displayStartPickModal" @close="handleCloseStartPickModal" />
    </template>
    <v-skeleton-loader v-if="loadingOrders"
                       type="article"/>
    <div class="text-center">
      <v-pagination
          :value="page"
          @input="handlePage"
          :length="totalPages"
      ></v-pagination>
    </div>
    <v-navigation-drawer v-model="filtersVisible" app right temporary width="80%">
      <filters v-if="filtersVisible"
               @close="filtersVisible = false"
               @change="getOrders"
      />
    </v-navigation-drawer>
  </div>
</template>

<script>
import Filters from '../orders/Filters'
import router from "@/bootstrap/router"
import StartPickModal from "@/components/order/StartPickModal"
import {ORDER_STEP_ID_SPLITPACK} from "../../bootstrap/config";

export default {
  name: 'Orders',
  components: {
    StartPickModal,
    'filters': Filters
  },
  data: () => ({
    filtersVisible: false,
    loadingOrders: true,
    loadingOverview: true,
    loadingOrderId: null,
    displayStartPickModal: false,
  }),
  computed: {
    displayClass() {
      return (order) => ({
        taken: order.taken || order.splitPack,
        'deep-purple lighten-3': order.taken || order.splitPack,
        'grey lighten-1': this.orderCustomerHasStartPickFromBarcodeEnabled(order.customer)
      })
    },
    appliedFilters() {
      const appliedFilters = [
        this.$store.state.orders.customerFilter,
        this.$store.state.orders.orderTypeFilter,
        this.$store.state.orders.courierFilter,
        this.$store.getters['orders/getSortName']
      ]
      return appliedFilters.filter(item => !!item).join('/')
    },
    orders() {
      return this.$store.state.orders.orders
    },
    totalFiltered() {
      return this.$store.state.orders.totalFiltered
    },
    page() {
      return this.$store.state.orders.page
    },
    totalPages() {
      return Math.ceil(this.totalFiltered / this.$store.state.orders.perPage)
    },
    total() {
      return this.$store.state.orders.total
    },
    isMarkedAsSplitPack() {
      return this.$store.getters['picking/getState'] === ORDER_STEP_ID_SPLITPACK
    },
    startPickFromBarcode() {
      return this.$store.state.orders.startPickFromBarcode
    },
    startPickFromBarcodeEnabled() {
      for (const credential of this.startPickFromBarcode) {
        if (credential?.start_pick_from_barcode === 1) {
          return true
        }
      }
      return false
    },
    orderCustomerHasStartPickFromBarcodeEnabled() {
      return (customerHubooBox) => {
        for (const credential of  this.startPickFromBarcode) {
          if (credential?.start_pick_from_barcode === 1 && credential?.huboobox === customerHubooBox) {
            return true
          }
        }
        return false
      }
    },
  },
  created() {
    this.getOrdersOverview()
    this.getOrders()
  },
  methods: {
    startOrder(orderId, customer) {
      if (this.orderCustomerHasStartPickFromBarcodeEnabled(customer)) {
        this.displayStartPickModal = true
        return
      }
      router.push({name: 'order', params: {orderId: orderId}})
    },
    goToStartPickFromBarcode() {
      router.push({name: 'start-pick-with-barcode', params: {hub_id: this.$store.state.core.hubId}})
    },
    handleCloseStartPickModal() {
      this.displayStartPickModal = false
    },
    handlePage(page) {
      this.$store.commit('orders/setPage', page)
      this.getOrders()
    },
    async getOrdersOverview() {
      this.loadingOverview = true
      await this.$store.dispatch('orders/getOrdersOverview')
      this.loadingOverview = false
    },
    async getOrders() {
      this.loadingOrders = true
      await this.$store.dispatch('orders/getOrders')
      this.loadingOrders = false
    },
  }
}
</script>

<style scoped>
caption {
  font-size: 0.75em;
}
</style>

<style scoped lang="scss">
@import '~vuetify/src/styles/styles.sass';

.taken > * {
  opacity: 0.7;
}

@media #{map-get($display-breakpoints, 'sm-and-up')}{
  .v-btn.pick-button {
    height: 100%
  }
}
</style>
