<template>
  <div>
    <v-form @submit.prevent="submit">
      <v-select
          id="font-size"
          v-model="zoomScale"
          :items="items"
          :label="$t('font_size')"
          @change="submit"></v-select>
      <p class="v-label v-label--active theme--light">{{ $t('device_type') }}:</p>
      <v-btn-toggle
          class="d-flex flex-column flex-sm-row"
          v-model="device"
          id="device-type"
          tile
          group
          mandatory
          @change="submit"
      >
        <v-btn id="default-device" value="zebra">
          {{ $t('inbuilt_scanner') }}
        </v-btn>
        <v-btn id="glove-device" value="glove_scanner">
          {{ $t('glove_scanner') }}
        </v-btn>
      </v-btn-toggle>
    </v-form>
  </div>
</template>

<script>
import { bus } from '@/bootstrap/bus'
export default {
  name: "Settings",
  data() {
    return {
      device: "zebra",
      zoomScale: 1,
      items: [
        {
          text: this.$t('standard'),
          value: 1,
        },
        {
          text: this.$t('large'),
          value: 1.15,
        },
        {
          text: this.$t('extra_large'),
          value: 1.3,
        }
      ]
    }
  },
  methods: {
    submit() {
      bus.$emit('zoomChanged', this.zoomScale);
      this.$cookies.set('device', this.device);
    }
  },
  created() {
    if (this.$cookies) {
      this.zoomScale = Number(this.$cookies.get('zoom')) ? parseFloat(this.$cookies.get('zoom')) : 1
      this.device = this.$cookies.get('device');
    }
  }
}
</script>
