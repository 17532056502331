import { datadogLogs } from '@datadog/browser-logs'
import { DATADOG_CLIENT_TOKEN, ENABLE_LOGGING, STAGE } from '@/bootstrap/config'
import {version} from '@/../package'

class DataDogLogService {
    applicationName = 'perfect-picking'
    init() {
        if (!ENABLE_LOGGING) {
            return
        }
        datadogLogs.init({
            clientToken: DATADOG_CLIENT_TOKEN,
            site: 'datadoghq.eu',
            env: STAGE,
            service: this.applicationName,
            forwardErrorsToLogs: true,
            sampleRate: 100,
            version
        })
    }
}

export default new DataDogLogService()
