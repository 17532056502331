
import PickingItems from "@/components/order/PickingItems.vue"
import Packing from "@/components/order/Packing.vue"
import Insert from "@/components/order/Insert.vue"
import SplitPack from "@/components/order/SplitPack.vue";
import DispatchNote from "@/components/order/DispatchNote.vue";
import auditLog from "@/bootstrap/auditLog"
import {
  ORDER_STEP_ID_INSERT,
  ORDER_STEP_ID_PACK,
  ORDER_STEP_ID_PICK,
  ORDER_STEP_ID_SPLITPACK,
  ORDER_STEP_ID_DISPATCH_NOTE
} from "@/bootstrap/config";
import {api} from "@/bootstrap/api";
import mapOrder from "@/utilities/mapOrder";
import {OrderInProgressConfirmationActions} from "@/enums/OrderInProgressConfirmationActions";
import Vue from "vue";

export default Vue.extend({
  name: 'Picking',
  components: {
    DispatchNote,
    SplitPack,
    Packing,
    PickingItems,
    Insert,
  },
  data: () => ({
    loadingOrder: true as boolean,
    confirmStartOrder: false as boolean,
  }),
  props: {
    orderId: {
      required: true as boolean
    }
  },
  computed: {
    orderItemsVisible(): boolean {
      return this.$store.getters['picking/getState'] === null ||
          this.$store.getters['picking/getState'] === ORDER_STEP_ID_PICK ||
          this.$store.getters['picking/getState'] === ORDER_STEP_ID_SPLITPACK
    },
    insertVisible(): boolean {
      return this.$store.getters['picking/getState'] === ORDER_STEP_ID_INSERT
    },
    packingVisible(): boolean {
      return this.$store.getters['picking/getState'] === ORDER_STEP_ID_PACK
    },
    dispatchNoteVisible(): boolean {
      return this.$store.getters['picking/getState'] === ORDER_STEP_ID_DISPATCH_NOTE
    },
    splitPackVisible(): boolean {
      return this.$store.getters['picking/isSplitable']
    },
  },
  created() {
    this.startOrder()
  },
  beforeDestroy() {
    this.commitAuditLog()
  },
  methods: {
    async startOrder() {
      const results = await api(
          'order',
          {
            params: {
              order_id: this.orderId,
            }
          }
      )
      const order = mapOrder(results.data)
      if (
          order.taken
      ) {
        this.confirmStartOrder = true
        return
      }
      await this.startOrderForce()
    },
    async continuePick() {
      auditLog.logOrderInProgressDialogResponse(OrderInProgressConfirmationActions.PICK)
      await this.startOrderForce()
    },
    async startOrderForce() {
      await this.$store.dispatch('picking/start', this.orderId)
      this.confirmStartOrder = false
      this.loadingOrder = false
      auditLog.logStartPicking()
    },
    commitAuditLog() {
      auditLog.commit()
    },
    redirect() {
      auditLog.logOrderInProgressDialogResponse(OrderInProgressConfirmationActions.CANCEL)
      this.$store.dispatch('core/redirectToOrders')
    }
  },
  watch: {
    orderId() {
      this.startOrder()
    }
  }
})
