<template>
  <div>
    <v-row v-masonry="">
        <div class="col-12 col-md-3 product mb-0" v-for="item in items" :key="item.id">
          <v-card @click="challengeConfirmation(item)" :id="'pick_item_' + item.id">
            <v-list-item three-line>
              <v-list-item-content>
                <div class="align-center d-flex flex-wrap mb-3">
                  <div>
                    <p class="text--secondary mb-0">
                      {{ $t('product') }}
                    </p>
                    <p class="display-1 mb-0 font-weight-medium">
                      {{ item.sku }}
                    </p>
                  </div>
                  <v-spacer/>
                  <div>
                    <p class="text--secondary mb-0">
                      {{ $t('location') }}
                    </p>
                    <p class="display-1 mb-0">
                      {{ getLocation(item) }}
                    </p>
                  </div>
                </div>
                <div class="d-flex">
                  <div class="mr-3">
                    <p class="mb-0">
                      {{ capitalize(item.title) }}
                    </p>
                    <p class="text--secondary mt-3 mb-0">
                      {{ $t('quantity') }}:
                      <span class="text--primary font-weight-bold">
                        {{ displayQuantity(item) }}
                      </span>
                    </p>
                    <p class="text--secondary mt-2 mb-0"
                       v-for="(variation, variation_name) in item.variations"
                       :key="variation_name">
                      {{ variation_name }}:
                      <span class="text--primary font-weight-bold">
                        {{ variation }}
                      </span>
                    </p>
                  </div>
                  <v-spacer/>
                  <v-img
                      :lazy-src="lazyImageSource"
                      :src="item.image"
                      max-height="80"
                      max-width="80"
                      height="80"
                      @load="redrawMasonry"
                      @error="redrawMasonry"
                  />
                </div>
              </v-list-item-content>
            </v-list-item>
            <v-overlay
                :absolute="true"
                :value="isItemPicked(item.id)"
                :opacity="0.25"
            />
          </v-card>
        </div>
    </v-row>
    <quantity-challenge
        v-if="quantityChallenge"
        :quantity-required="quantityChallenge"
        @close="closeChallenges"
        @pass="passQuantityChallenge"
    />
    <confirmation-challenge
        v-if="confirmationChallenge"
        :check-code-required="confirmationChallenge"
        @close="closeChallenges"
        @pass="passConfirmationChallenge"
    />
    <location-challenge
        v-if="locationChallenge"
        :items="locationChallenge"
        @close="closeChallenges"
        @pass="passLocationChallenge"
    />
    <mispick-challenge
        v-if="mispickChallenge"
        :message="mispickChallenge"
        @close="closeChallenges"
    />
  </div>
</template>

<script>
import ConfirmationChallenge from "./ConfirmationChallenge"
import LocationChallenge from "./LocationChallenge"
import MispickChallenge from "./MispickChallenge"
import QuantityChallenge from "./QuantityChallenge"
import {bus} from "@/bootstrap/bus"
import auditLog from "@/bootstrap/auditLog"
import lodash from 'lodash'

export default {
  name: "PickingItems",
  data: () => ({
    lazyImageSource: 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7',
    quantityChallenge: false,
    mispickChallenge: false,
    confirmationChallenge: false,
    locationChallenge: false,
    currentItem: null,
    currentBatchId: null
  }),
  components: {
    ConfirmationChallenge,
    LocationChallenge,
    MispickChallenge,
    QuantityChallenge,
  },
  computed: {
    items() {
      const items = this.$store.state.picking.order.items
      items.sort((a, b) => {
        const aPicked = this.isItemPicked(a.id);
        const bPicked = this.isItemPicked(b.id);
        if (aPicked === bPicked && a.location) {
          return a.location.localeCompare(b.location)
        }
        return (aPicked > bPicked) ? 1 : -1
      })
      return items
    },
    isItemPicked() {
      return function (itemId) {
        return this.$store.state.picking.picked.indexOf(itemId) !== -1
      }.bind(this)
    },
    getLocation() {
      return function (item) {
        let location = ''
        if (parseInt(item.hubId) !== parseInt(this.$store.state.core.hubId)) {
          location += item.hubId + ' - '
        }
        location += item.location
        return location
      }
    },
    capitalize() {
      return function (value) {
        return lodash.capitalize(value)
      }
    },
    isChallenged() {
      return this.quantityChallenge ||
          this.mispickChallenge ||
          this.confirmationChallenge ||
          this.locationChallenge
    },
    displayQuantity() {
      return function (item) {
        if (item.quantity > 1 && item.batches.length) {
          return `${item.batches.length}/${item.quantity}`
        }
        return item.quantity
      }
    },
  },
  created() {
    bus.$on('barcodeScanned', this.handleItemScan)
  },
  beforeDestroy() {
    bus.$off('barcodeScanned', this.handleItemScan)
  },
  methods: {
    redrawMasonry() {
      if (typeof this.$redrawVueMasonry === 'function') {
        this.$redrawVueMasonry()
      }
    },
    async markCurrentItemAsPicked() {
      this.$store.commit('picking/markItemAsPicked', this.currentItem.id)
      this.currentBatchId = null
      this.currentItem = null
      this.$nextTick(() => {
        this.$redrawVueMasonry()
      })
      if (this.$store.getters['picking/unpickedItems'].length === 0) {
        auditLog.logStartPacking(this.$store.state.picking.order)
        await this.$store.dispatch('picking/setOrderInProgress')
        this.$store.dispatch('picking/transitionState')
      }
    },
    closeChallenges() {
      this.confirmationChallenge = false
      this.locationChallenge = false
      this.mispickChallenge = false
      this.quantityChallenge = false
      this.currentItem = null
      this.currentBatchId = null
    },
    passQuantityChallenge() {
      this.quantityChallenge = false
      this.markCurrentItemAsPicked()
    },
    passLocationChallenge(item) {
      this.locationChallenge = false
      this.currentItem = item
      this.challengeQuantity()
    },
    passConfirmationChallenge(reason) {
      this.confirmationChallenge = false
      auditLog.logManualPick(this.currentItem.sku, reason)
      this.challengeQuantity()
    },
    challengeIncorrectScan(scanResult, sku, batchId) {
      this.mispickChallenge = this.$t('invalid_barcode_scan')
      auditLog.logMispick(scanResult, sku, batchId)
    },
    challengeAlreadyPicked(scanResult) {
      this.mispickChallenge = this.$t('item_already_picked')
      this.mispickChallenge += '\n\r'
      this.mispickChallenge += scanResult
    },
    challengeManualPickOfBatchedItem() {
      this.mispickChallenge = this.$t('batched_item_cannot_be_manually_picked')
    },
    challengeBarcodeScanOfBatchedItem() {
      this.mispickChallenge = this.$t('batched_item_cannot_be_scanned_by_barcode')
    },
    challengeMismatchedLocationOfBatchedItem() {
      this.mispickChallenge = this.$t('batched_item_location_mismatch')
    },
    challengeNonBatchScanOfBatchedItem() {
      this.mispickChallenge = this.$t('batched_item_invalid_qr_code')
    },
    challengeQuantity() {
      this.storeBatch()
      if (this.currentItem.quantity > 1) {
        if (this.currentItem.batches.length === 0) {
          this.quantityChallenge = this.currentItem.quantity
          return
        } else if (this.currentItem.batches.length < this.currentItem.quantity) {
          return
        }
      }
      this.markCurrentItemAsPicked()
    },
    challengeConfirmation(item) {
      this.currentItem = item
      if (this.isItemPicked(item.id)) {
        this.challengeAlreadyPicked(item.sku)
        return
      }
      // Items with batches are not supposed to be picked manually - show warning
      if(this.currentItem.valid_batch_ids.length) {
        this.challengeManualPickOfBatchedItem()
        return;
      }
      this.confirmationChallenge = item.checkCode
    },
    challengeLocation(items) {
      this.locationChallenge = items
    },
    extractElements(scanResult) {
      const scanResultRegex = /^(\d+)-(?:batch-(\d+))?/i
      const match = scanResult.match(scanResultRegex)
      if (match && match[1]) {
        return match.slice(1)
      }
      return []
    },
    extractItemsBySkuOrBarcode: function (scanResult, sku) {
      if (sku) {
        return this.items.filter((item) => {
          this.$store.commit('picking/resetScannedBarcode')
          return sku.toString() === item.sku.toString()
        })
      }
      // Mark item if it has been scanned by barcode
      return this.items.filter((i) => i.scannedByBarcode = i.barcodes.indexOf(scanResult.toString()) !== -1)
    },
    handleItemScan(scanResult) {
      // Do not confirm picks until all challenges are passed or closed
      if (this.isChallenged) {
        return
      }
      const [ sku = null, batchId = null ] = this.extractElements(scanResult);
      this.currentBatchId = batchId ? parseInt(batchId) : null;
      const items = this.extractItemsBySkuOrBarcode(scanResult, sku);
      this.handleItemPick(items, scanResult, sku);
    },
    storeBatch: function () {
      if (this.currentBatchId) {
        this.$store.commit('picking/storeBatch', {
          'itemId': this.currentItem.id,
          'batchId': this.currentBatchId,
        })
      }
    },
    handleItemPick: function (items, scanResult, sku) {
      // Item does not belong to the current order
      if (items.length === 0 ) {
        this.challengeIncorrectScan(scanResult, sku, this.currentBatchId)
        return
      }
      const pickedItems = items.filter((item) => {
        return this.isItemPicked(item.id)
      })
      // All items with the same sku are already picked
      if (pickedItems.length >= items.length) {
        this.challengeAlreadyPicked(sku || scanResult)
        return
      }
      const scannedByBarcode = items.filter((item) => {
        return item.scannedByBarcode === true && item.valid_batch_ids?.length
      })
      if (scannedByBarcode.length > 0) {
        this.challengeBarcodeScanOfBatchedItem()
        return
      }
      // Batched items
      const pickedItemsInValidBatches = items.filter((item) => {
        if (item.valid_batch_ids.length === 0) return true
        return item.valid_batch_ids.indexOf(this.currentBatchId) !== -1
      })
      if (pickedItemsInValidBatches.length === 0) {
        if (this.currentBatchId) {
          this.challengeMismatchedLocationOfBatchedItem()
        } else {
          this.challengeNonBatchScanOfBatchedItem()
        }
        return
      }
      const pickedCountForCurrentBatch = items.reduce((itemTotal, item) => {
        if (this.currentBatchId && item.batches.length) {
          itemTotal += item.batches.reduce((batchTotal, batch) => {
            if (batch === this.currentBatchId) {
              ++batchTotal
            }
            return batchTotal
          }, 0)
        }
        return itemTotal
      }, 0)
      if (pickedItemsInValidBatches.length === 1 && items.length > 1) {
        if (pickedItemsInValidBatches[0].quantity <= pickedCountForCurrentBatch) {
          this.challengeAlreadyPicked(scanResult)
          return
        }
        items = pickedItemsInValidBatches
      }
      const unpickedItems = items.filter((item) => {
        return !this.isItemPicked(item.id)
      })
      // There are multiple items still not picked with the same sku from different locations
      if (unpickedItems.length > 1) {
        this.challengeLocation(unpickedItems)
        return
      }
      this.currentItem = unpickedItems[0] ?? items[0]
      auditLog.logItemScan(scanResult, sku, this.currentBatchId)
      this.challengeQuantity()
    },
  }
}
</script>
