import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
theme: {
    themes: {
      light: {
        primary: '#5a5fa0',
        secondary: '#02db9b',
        accent: '#ffbf00'
      },
    },
  },
})