import mapOrder from "@/utilities/mapOrder";
import setState from "@/utilities/store"
import {api} from "@/bootstrap/api"
import {
    ORDER_STEP_ID_COMPLETE,
    ORDER_STEP_ID_INSERT,
    ORDER_STEP_ID_PACK,
    ORDER_STEP_ID_PICK,
    ORDER_STEP_ID_SPLITPACK,
    ORDER_STEP_ID_DISPATCH_NOTE,
    ORDER_TYPE_FILTER_MULTI
} from "@/bootstrap/config"
import {
    isDispatchStepRequired,
    isPickingStepRequired,
    isPackingSlipStepRequired,
    isPackingStepRequired,
    isComplete
} from "@/utilities/pickingStepDecider"
import auditLog from "../bootstrap/auditLog";
import {
    REDIRECT_TO_NEXT_ORDER_ON_PICK_COMPLETE,
    REDIRECT_TO_ORDERS_ON_PICK_COMPLETE,
    REDIRECTION_STRATEGIES
} from "../bootstrap/config";

const defaultState = () => ({
    order: {},
    picked: [],
    packageTypes: [],
    insertBarcode: null,
    insertConfirmation: null,
    batches: [],
    redirectionStrategy: REDIRECT_TO_ORDERS_ON_PICK_COMPLETE
})

export default {
    state: defaultState(),
    getters: {
        getOrderId (state) {
            return state.order.id
        },
        requiresDispatch (state) {
            return state.order.requiresDispatch
        },
        requiresInsert (state) {
            return state.order.requiresInsert
        },
        getInsertConfirmationValues (state) {
            return state.insertConfirmation
        },
        hasInsert (state) {
            return !!state.insertBarcode
        },
        itemRequiresSpecialPackaging () {
            // @todo required implementation
            return false
        },
        mappedPackageTypes (state) {
            return state.packageTypes.map(item => {
                return {
                    id: item.id,
                    type: item.container_type,
                    name: item.container_name,
                }
            })
        },
        unpickedItems (state) {
            return state.order.items.filter(item => {
                return state.picked.indexOf(item.id) === -1
            })
        },
        getState (state) {
            return state.order.orderHeaderStepId
        },
        getPackageIdentifier (state) {
            return state.order.packageIdentifier
        },
        getPreferredContainerTypeId (state) {
            return state.order.preferredContainerTypeId
        },
        getCompletionRedirectionStrategy (state) {
            return state.redirectionStrategy
        },
        getOrderType (state) {
            return state.order.orderType
        },
        getSplitPack (state) {
            return state.order.splitPack
        },
        isSplitable (state) {
            if (state.order.orderType === ORDER_TYPE_FILTER_MULTI &&
                (
                    state.order.orderHeaderStepId === ORDER_STEP_ID_PICK ||
                    state.order.orderHeaderStepId === ORDER_STEP_ID_SPLITPACK
                )) {
                const uniqueSkus = new Set(state.order.items.map((item) => item.sku))
                return uniqueSkus.size > 1
            }
            return false
        },
        getBatchDetails(state) {
            const batches = []
            state.order.items.forEach(item => {
                item.batches.forEach(batchId => {
                    const key = `${item.id}-${batchId}`
                    if (batches[key]) {
                        ++batches[key].quantity
                    } else {
                        batches[key] = {
                            taskId: item.id,
                            batchId: batchId,
                            quantity: 1
                        }
                    }
                })
            })
            return Array.from(Object.keys(batches), key => batches[key]);
        },
    },
    mutations: {
        setOrder (state, order) {
            state.order = mapOrder(order)
        },
        resetPicked (state) {
            state.picked = []
            state.insertBarcode = null
        },
        resetState (state) {
            setState(state, defaultState())
        },
        markItemAsPicked (state, id) {
            state.picked.push(id)
        },
        setPackageTypes (state, packageTypes) {
            state.packageTypes = packageTypes
        },
        setInsertConfirmationValues (state, insertConfirmation) {
            state.insertConfirmation = insertConfirmation
        },
        setInsertBarcode (state, insertBarcode) {
            state.insertBarcode = insertBarcode
        },
        setOrderHeaderStepId (state, step) {
            state.order.orderHeaderStepId = step
        },
        setPackageIdentifier (state, packageIdentifier) {
            state.order.packageIdentifier = packageIdentifier
        },
        setCompletionRedirectionStrategy (state, redirectionStrategy) {
            state.redirectionStrategy = redirectionStrategy
        },
        toggleSplitPack (state) {
            state.order.splitPack = !state.order.splitPack
        },
        resetScannedBarcode (state) {
            state.order.items.map(orderItem => orderItem.scannedByBarcode = false)
        },
        storeBatch (state, data) {
            state.order.items.filter(item => {
                if (item.id === data.itemId && item.batches.length < item.quantity) {
                    item.batches.push(data.batchId)
                }
            })
        },
    },
    actions: {
        async start ({commit, dispatch}, orderId) {
            dispatch('getPackageTypes')
            commit('resetPicked')
            const results = await api(
                'startOrder',
                {
                    params: {
                        order_id: orderId
                    }
                }
            )
            commit('setOrder', results.data)
            commit('core/setTitle', results.data.shipment_id, {root: true})
            dispatch('transitionState')
        },
        async getPackageTypes ({commit}) {
            const results = await api('packageTypes')
            commit('setPackageTypes', results.data)
        },
        setOrderStep ({commit, dispatch}, step) {
            commit('setOrderHeaderStepId', step)
            dispatch('patchOrder', {
                order_header_step_id: step
            })
        },
        patchOrder ({getters}, data) {
            api(
                'patchOrder',
                {
                    data: data,
                    params: {
                        order_id: getters.getOrderId,
                    }
                }
            )
        },
        async complete ({commit, dispatch, getters}, {selectedPackageType, packageIdentifier, redirectionStrategy}) {
            if (!REDIRECTION_STRATEGIES.includes(redirectionStrategy)) {
                throw Error(`"${redirectionStrategy}" is not a valid redirection strategy.`)
            }
            await api('patchOrder', {
                params: {
                    order_id: getters['getOrderId'],
                },
                data: {
                    package_identifier: packageIdentifier,
                    preferred_container_type_id: selectedPackageType
                },
                handleError: false
            })
            if (
                getters['getPreferredContainerTypeId'] !== null &&
                getters['getPreferredContainerTypeId'] !== selectedPackageType
            ) {
                auditLog.logChangedPackaging(getters['getPreferredContainerTypeId'], selectedPackageType)
            }
            auditLog.logCompletePacking(selectedPackageType, packageIdentifier, redirectionStrategy)
            commit('setPackageIdentifier', packageIdentifier)
            // Specifies where to redirect to, used by this.redirect() which is called in "transitionState" action
            commit('setCompletionRedirectionStrategy', redirectionStrategy)
            dispatch('transitionState')
        },
        async setOrderInProgress ({getters}) {
            await api(
                'pickOrder',
                {
                    data: {
                        batchDetails: getters.getBatchDetails,
                    },
                    params: {
                        order_id: getters.getOrderId,
                    }
                }
            )
        },
        async transitionState ({dispatch, getters}) {
            if (isPickingStepRequired(getters)) {
                if (getters['getState'] === null) {
                    dispatch('setOrderStep', ORDER_STEP_ID_PICK)
                }
                return
            }

            if (isDispatchStepRequired(getters)) {
                dispatch('setOrderStep', ORDER_STEP_ID_DISPATCH_NOTE)
                return
            }

            if (isPackingSlipStepRequired(getters)) {
                dispatch('setOrderStep', ORDER_STEP_ID_INSERT)
                return
            }

            if (isPackingStepRequired(getters)) {
                dispatch('setOrderStep', ORDER_STEP_ID_PACK)
                return
            }

            if (isComplete(getters)) {
                await dispatch('setOrderStep', ORDER_STEP_ID_COMPLETE)
                if (getters['getCompletionRedirectionStrategy'] === REDIRECT_TO_NEXT_ORDER_ON_PICK_COMPLETE) {
                    dispatch('core/redirect', {name: 'start-next-order'}, {root: true})
                } else {
                    dispatch('core/redirectToOrders', {}, {root: true})
                }
                return
            }

            if (getters['getState'] === ORDER_STEP_ID_COMPLETE) {
                dispatch('core/redirectToOrders', {}, {root: true})
                return
            }
        },
    },
    namespaced: true
}
