import {ORDER_TYPE_FILTER_SINGLES} from "@/bootstrap/config"
import store from "@/bootstrap/store"
import i18n  from '@/plugins/i18n';

import {EMPTY_VARIATION, STATUS_ALLOCATED, STATUS_IN_PROGRESS, ORDER_STEP_ID_SPLITPACK} from "@/bootstrap/config";

export default function mapOrder(order) {
    let taken = false
    if (
        order.in_progress_by_user_id !== null &&
        order.in_progress_by_user_id !== store.getters["core/getAuthUserId"]
    ) {
        taken = true
    }
    let customer = order.huboo_box
    if (typeof order.huboo_box === 'object') {
        customer = order.huboo_box.huboobox
    }
    let requiresInsert = false
    if (order.huboo_box?.hubpic_user?.requires_insert) {
        requiresInsert = order.huboo_box?.hubpic_user?.requires_insert
    }
    let specialPackagingRequiredByCustomer = false
    if (order.huboo_box?.special_packaging_required) {
        specialPackagingRequiredByCustomer = order.huboo_box?.special_packaging_required
    }

    const mappedOrder = {
        attributes: {},
        courier: order.tracking_code?.carrier,
        customer: customer,
        id: order.id,
        itemQuantity: order.order_lines.reduce((a, b) => a + b.qty_tasked, 0),
        items: mapOrderLines(order.order_lines),
        orderHeaderStepId: order.order_header_step_id,
        orderType: order.work_group,
        packageIdentifier: order.package_identifier,
        packagingId: order.packaging ? order.packaging.packaging_id : null,
        packagingName: order.packaging ? order.packaging.name : null,
        packagingType: order.packaging ? order.packaging.container_type : null,
        preferredContainerTypeId: order.preferred_container_type_id,
        requiresDispatch: order.requires_dispatch_note,
        requiresInsert: requiresInsert,
        shipmentId: order.shipment_id,
        sku: order.order_lines[0].sku_id,
        specialPackagingRequiredByCustomer: specialPackagingRequiredByCustomer,
        startPickFromBarcode: order.huboo_box?.start_pick_from_barcode ?? false,
        status: order.status,
        taken: taken,
        userId: order.in_progress_by_user_id,
        splitPack: order.order_header_step_id === ORDER_STEP_ID_SPLITPACK,
        scannedByBarcode: false
    }

    mappedOrder.attributes = extractAttributes(mappedOrder);

    return mappedOrder
}

function extractVariations(orderLine) {
    const variations = {}
    if (orderLine.hubpic_channel_detail) {
        const hubpic_channel_detail = orderLine.hubpic_channel_detail
        if (hubpic_channel_detail.variation_name !== EMPTY_VARIATION) {
            variations[hubpic_channel_detail.variation_name] = hubpic_channel_detail.variation_value
        }
        if (hubpic_channel_detail.variation_name2 !== EMPTY_VARIATION) {
            variations[hubpic_channel_detail.variation_name2] = hubpic_channel_detail.variation_value2
        }
        if (hubpic_channel_detail.variation_name3 !== EMPTY_VARIATION) {
            variations[hubpic_channel_detail.variation_name3] = hubpic_channel_detail.variation_value3
        }
        if (hubpic_channel_detail.variation_name4 !== EMPTY_VARIATION) {
            variations[hubpic_channel_detail.variation_name4] = hubpic_channel_detail.variation_value4
        }
    }
    return variations
}

function extractAttributes(mappedOrder) {
    const attributes = {};

    attributes[i18n.t('customer')] = mappedOrder.customer
    attributes[i18n.t('status')] = extractStatus(mappedOrder.status)
    attributes[i18n.t('order_type')] = mappedOrder.orderType
    attributes[i18n.t('quantity')] = mappedOrder.itemQuantity

    if (mappedOrder.courier) {
        attributes[i18n.t('courier')] = mappedOrder.courier
    }

    if (mappedOrder.orderType === ORDER_TYPE_FILTER_SINGLES) {
        attributes[i18n.t('sku')] = mappedOrder.sku
    }
    return attributes;
}

function extractStatus(status) {
    switch (status.trim()) {
        case STATUS_IN_PROGRESS:
            return i18n.t('in_progress');

        case STATUS_ALLOCATED:
            return i18n.t('allocated');

        default:
            return status;
    }
}

function extractBarcodes(orderLine) {
    if (orderLine.hubpic_channel_detail) {
        return orderLine.hubpic_channel_detail.barcodes.map(item => item.barcode)
    }
    return []
}

export function mapOrderLines(orderLines) {
    const items = []
    for (let i = 0; i < orderLines.length; i++) {
        const orderLine = orderLines[i]
        const variations = extractVariations(orderLine);
        if (orderLine.tasks) {
            for (let j = 0; j < orderLine.tasks.length; j++) {
                const task = orderLine.tasks[j]
                const item = {
                    barcodes: extractBarcodes(orderLine),
                    checkCode: ('00' + orderLine.sku_id % 999).slice(-3),
                    hubId: task.hub_id,
                    id: task.id,
                    image: orderLine.hubpic_channel_detail?.eBay_pictureURL,
                    location: task.from_loc,
                    quantity: task.qty_to_move,
                    sku: orderLine.sku_id,
                    title: orderLine.hubpic_channel_detail?.eBay_item_title,
                    valid_batch_ids: task.valid_batch_ids,
                    variations: variations,
                    batches: [],
                }
                items.push(item)
            }
        }
    }
    return items
}
