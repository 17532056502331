<template>
  <v-list nav dense>
    <v-list-item-group
        v-model="group"
        active-class="primary--text"
    >
      <v-list-item :to="{name: 'orders'}">
        <v-list-item-icon>
          <v-icon>
            mdi-package-variant
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ $t('orders') }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item :to="{name: 'hub_selector'}">
        <v-list-item-icon>
          <v-icon>
            mdi-home-city-outline
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ $t('change_hub') }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item :to="{name: 'settings'}">
        <v-list-item-icon>
          <v-icon>
            mdi-cog
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ $t('settings') }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-icon>
          <v-icon>
            mdi-logout
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title @click="logout" id="btn_logout">
            {{ $t('logout') }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
export default {
  name: 'Navigation',
  data: () => ({
    group: null
  }),
  methods: {
    logout() {
      this.$store.dispatch('core/logout')
    },
  },
}
</script>
