<template>
  <v-snackbar :value="hasErrors"
              timeout="-1"
              color="error"
  >
    <div class="d-flex align-center">
      <v-icon class="mr-3">
        mdi-alert-outline
      </v-icon>
      <div class="flex-shrink-1">
        <p v-for="(message, index) in errorsMessages" :key="index" class="ma-0">
          {{ message }}
        </p>
      </div>
    </div>
    <div slot="action">
      <v-btn id="error_close_btn" text @click="resetErrorsImmediately()">
        {{ $t('close') }}
      </v-btn>
    </div>
  </v-snackbar>
</template>

<script>
export default {
  name: 'errors',
  data: () => ({}),
  computed: {
    hasErrors() {
      return !!this.errors.length
    },
    errors() {
      return this.$store.state.core.errors
    },
    errorsMessages() {
      const messages = []
      for (const key in this.errors) {
        const error = this.errors[key]
        let message = error.message
        if (typeof error.response !== 'undefined') {
          const response = error.response
          if (response.status) {
            message = response.status + ' - '
          }
          if (parseInt(response.status) === 422) {
            message += this.extract422Errors(response.data);
          } else {
            message += this.extractMessageFromResponseDataError(response.data)
          }
        }
        messages.push(message)
      }
      return messages
    }
  },
  methods: {
    extract422Errors(data) {
      const errors = [];
      for (const key in data.errors) {
        for (let i = 0; i < data.errors[key].length; i++) {
          errors.push(data.errors[key][i])
        }
      }
      return errors.join('\n')
    },
    extractMessageFromResponseDataError(response) {
      const messageKeys = [
        'message',
        'statusText',
        'error',
        'data',
      ]
      for (const index in messageKeys) {
        if (typeof response[messageKeys[index]] != 'undefined') {
          return response[messageKeys[index]]
        }
      }
      return this.$t('oops_something_went_wrong');
    },
    resetErrorsImmediately() {
      this.$store.dispatch('core/resetErrorsIgnoringTimeout')
    }
  }
}
</script>
