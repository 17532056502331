import {api} from "@/bootstrap/api";
import store from "@/bootstrap/store"
import moment from "moment";
import {AuditLogEventTypes} from "@/enums/AuditLogEventTypes";
import {OrderInProgressConfirmationActions} from "@/enums/OrderInProgressConfirmationActions";
import {Route} from "vue-router/types/router";
import {datadogLogs} from "@datadog/browser-logs";

type AuditLogEntry = {
  user_id: number|null,
  event: AuditLogEventTypes,
  data: object,
  created_at: string
}

export default class AuditLog {
    enabled: boolean
    logs: AuditLogEntry[] = []

    constructor(enabled = true) {
        this.enabled = enabled
    }

    add(
        event: AuditLogEventTypes,
        data: object = {}
    ) {
        const user_id = store.getters['core/getAuthUserId']
        this.logs.push({
            user_id,
            event,
            data,
            created_at: moment().toISOString()
        })
    }

    async commit() {
        if (!this.enabled) {
            this.logs = []
            return
        }
        if (this.logs.length === 0) {
            return
        }
        this.logs.forEach((logEntry: AuditLogEntry) => {
          datadogLogs.logger.info(logEntry.event, logEntry)
        })
        try {
          await api(
            'auditLog',
            {
              data: this.logs
            }
          )
        } catch (e) {
          datadogLogs.logger.error('Error posting audit logs', {exception: e})
        }
        this.logs = []
    }

    // User signs in - record site, hub, user id - date & time
    logSignIn() {
        this.add(
            AuditLogEventTypes.EVENT_SIGN_IN
        )
        this.commit()
    }

    // User changes hub - which hub and time
    logHubChange(hubId: number) {
        this.add(
            AuditLogEventTypes.EVENT_HUB_CHANGE,
            {
                hub_id: hubId,
            }
        )
        this.commit()
    }

    // User selects ‘pick’ an order
    logStartPicking() {
        this.add(
            AuditLogEventTypes.EVENT_START_PICKING,
            {
                order_id: store.getters['picking/getOrderId'],
                hub_id: store.state.core.hubId,
            }
        )
    }
    // Response to the "Order in progress" dialog on the picking screen
    logOrderInProgressDialogResponse(response: OrderInProgressConfirmationActions) {
        this.add(
            AuditLogEventTypes.EVENT_ORDER_IN_PROGRESS_RESPONSE,
            {
                order_id: store.getters['picking/getOrderId'],
                hub_id: store.state.core.hubId,
                response
            }
        )
    }

    // User scans item
    logItemScan(barcode: string, sku?: number, batchId?: number) {
        this.add(
            AuditLogEventTypes.EVENT_ITEM_SCAN,
            {
                order_id: store.getters['picking/getOrderId'],
                barcode: barcode,
                hub_id: store.state.core.hubId,
                sku,
                batchId
            }
        )
    }

    // If no barcode - log reason why
    logManualPick(sku: number, reason: string) {
        this.add(
            AuditLogEventTypes.EVENT_MANUAL_PICK,
            {
                sku: sku,
                reason: reason,
                order_id: store.getters['picking/getOrderId'],
            }
        )
    }

    // If incorrect item scanned for that order  - store the sku of the incorrect item
    logMispick(barcode: string, sku: number, batchId?: number) {
        this.add(
            AuditLogEventTypes.EVENT_MISPICK,
            {
                barcode: barcode,
                order_id: store.getters['picking/getOrderId'],
                hub_id: store.state.core.hubId,
                sku,
                batchId
            }
        )
    }

    // If single item order and user overrides suggested packaging - what should be & what choice of package
    logChangedPackaging(before: string, after: string) {
        this.add(
            AuditLogEventTypes.EVENT_PACKAGING_CHANGE,
            {
                order_id: store.getters['picking/getOrderId'],
                before: before,
                after: after
            }
        )
    }

    // When packing complete (submit / submit and next order)
    logCompletePacking(selectedPackageType: string, packageIdentifier: string, redirectionStrategy: string) {
        this.add(
            AuditLogEventTypes.EVENT_COMPLETE_PACKING,
            {
                selectedPackageType,
                packageIdentifier,
                redirectionStrategy
            }
        )
    }

    // When packing started (enter the packing screen)
    logStartPacking(order: object) {
        this.add(
            AuditLogEventTypes.EVENT_START_PACKING,
            order
        )
    }

    // If the scanned barcode cannot be found in start_pick_from_barcode
    logFailedToFindOrder(barcode: string) {
        this.add(
          AuditLogEventTypes.EVENT_FAIL_TO_FIND_ORDER,
          {
              order_id: barcode,
              hub_id: store.state.core.hubId,
          }
        )
    }

    // When route changes, allowing us to track an entire user journey throughout the application
    logRouteChange(from: Route, to: Route) {
      this.add(
        AuditLogEventTypes.EVENT_ROUTE_CHANGE,
        {
          from_name: from.name,
          from_params: from.params,
          from_path: from.path,
          to_name: to.name,
          to_params: to.params,
          to_path: to.path,
        }
      )
    }
}
