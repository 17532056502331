import axios from 'axios'
import store from '@/bootstrap/store'
import config from '@/api/config'
import moment from 'moment'

const APPLICATION_JSON = 'application/json'

function getUrl(route_name, params) {
    const endpoint = getEndpoint(route_name)
    let url = config.base_url + endpoint.url
    for (const param in params) {
        url = url.replace('{' + param + '}', params[param])
    }
    return url
}

function getEndpoint(route_name) {
    if (typeof config.endpoints[route_name] == 'undefined') {
        throw 'api does not have a defined endpoint for route ' + route_name
    }
    return config.endpoints[route_name]
}

function addJsonHeaders(headers) {
    headers['Content-Type'] = APPLICATION_JSON
    headers['Accept'] = APPLICATION_JSON
    return headers
}

async function addPassportHeaders(headers) {
    if (moment(store.state.core.accessTokenExpiresAt).unix() < moment().unix()) {
        await refreshAccessToken()
    }
    const accessToken = store.state.core.accessToken?.access_token
    if (accessToken){
        headers['Authorization'] = 'Bearer ' + accessToken
    }
    return headers
}

export async function fetchAccessToken(email, password) {
    const data = {
        client_id: config.client_id,
        client_secret: config.client_secret,
        username: email,
        password: password
    }
    const res = await api('accessToken', {auth: false, data: data, handleError: false})
    return res?.data
}

export async function refreshAccessToken() {
    const refreshToken = store.state.core.accessToken
    const data = {
        grant_type: 'refresh_token',
        client_id: config.client_id,
        client_secret: config.client_secret,
        refresh_token: refreshToken
    }
    const res = await api('refreshToken', {auth: false, data: data, method: 'POST'})
    store.commit('core/setAccessToken', res?.data)
}

export async function api(
    endpointName,
    {
        auth = true,
        data = {},
        headers = {},
        params = {},
        handleError = true
    } = {}
    ) {
        if (!endpointName) {
            throw 'api needs a valid target url'
        }
        store.dispatch('core/resetErrors')
        const endpoint = getEndpoint(endpointName)
        headers = addJsonHeaders(headers)
        const options = {
            data: {...endpoint.data, ...data},
            headers: auth ? await addPassportHeaders(headers) : headers,
            method: endpoint.method,
            params: {...endpoint.params, ...params},
            url: getUrl(endpointName, params)
        }
        return await axios(options)
            .then(r => r)
            .catch(e => {
                if (handleError) {
                    store.dispatch('core/handleError', e)
                }
                throw e
            })
    }
