import VueRouter from 'vue-router'
import Vue from "vue"
import routes from '../routes/index'
import store from '../bootstrap/store'
import {isFunction} from 'lodash'
import auditLog from "@/bootstrap/auditLog";

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    routes: routes
})

router.beforeEach((to, from, next) => {
    if (
        !store.getters['core/isAuthenticated'] &&
        to.name !== 'login'
    ) {
        next({name: 'login'})
        return
    }
    if (
        store.getters['core/isAuthenticated'] &&
        !store.state.core.hubId &&
        to.name !== 'hub_selector' &&
        to.name !== 'settings'
    ) {
        next({name: 'hub_selector'})
        return
    }

    store.commit('core/setRedirectToStartPickWithBarcode', (from.name === 'start-pick-with-barcode'));

    next()
})

router.afterEach((to) => {
    if (to.meta?.title) {
        store.commit('core/setTitle', isFunction(to.meta.title) ? to.meta.title() : to.meta.title)
    } else {
        store.commit('core/setTitle', null)
    }
})

export default router
