import {API_BASE_URL, API_CLIENT_ID} from '../bootstrap/config'

export default {
    base_url: API_BASE_URL,
    client_id: API_CLIENT_ID,
    endpoints: {
        accessToken: {
            method: 'post',
            url: '/api/v1/oauth-proxy/token/password',
            data: {
                grant_type: 'password',
                scope: ''
            }
        },
        refreshToken: {
            method: 'post',
            url: '/api/v1/oauth-proxy/token/refresh-token',
            data: {
                grant_type: 'refresh_token',
                scope: ''
            }
        },
        authUser: {
            method: 'get',
            url: '/api/v1/user'
        },
        sites: {
            method: 'get',
            url: '/api/v1/user/{user_id}/sites'
        },
        hubs: {
            method: 'get',
            url: '/api/v1/user/{user_id}/site/{site_id}/hubs'
        },
        allocatedOrders: {
            method: 'get',
            url: '/api/v1/order/allocated'
        },
        ordersOverview: {
            method: 'get',
            url: '/api/v1/order/allocated/overview'
        },
        ordersFilters: {
            method: 'get',
            url: '/api/v1/order/allocated/filters'
        },
        order: {
            method: 'get',
            url: '/api/v1/order/{order_id}'
        },
        remoteOrderId: {
            method: 'get',
            url: '/api/v1/order/remote-order-id/{remote_order_id}'
        },
        handleUnfoundRemoteOrderId: {
            method: 'post',
            url: '/api/v1/order/handle-unfound/{remote_order_id}'
        },
        startOrder: {
            method: 'post',
            url: '/api/v1/order/{order_id}/start'
        },
        pickOrder:{
            method: 'post',
            url: '/api/v1/order/{order_id}/pick'
        },
        logout: {
            method: 'get',
            url: '/api/v1/user/logout'
        },
        event: {
            method: 'post',
            url: '/api/v1/order/{order_id}/event/{event_id}'
        },
        packageTypes: {
            method: 'get',
            url: '/api/v1/container-types/all'
        },
        insertPrint: {
            method: 'post',
            url: '/api/v1/order/{order_id}/insert/print'
        },
        patchOrder: {
            method: 'patch',
            url: '/api/v1/order/{order_id}'
        },
        nextOrder: {
            method: 'post',
            url: '/api/v1/order/start-next-allocated'
        },
        auditLog:{
            method: 'post',
            url: '/api/v1/audit-log/bulk'
        }
    }
}
