<template>
  <div>
    <v-alert
        border="bottom"
        colored-border
        type="warning"
        elevation="2"
        v-if="hasWarnings"
        class="mb-3"
    >
      <template v-if="customerRequiresSpecialPackaging">
        {{ $t('special_packaging_required_client') }} <br/>
      </template>
      <template v-if="itemRequiresSpecialPackaging">
        {{ $t('special_packaging_requirements_item_level') }}
      </template>
    </v-alert>
    <v-alert
        v-if="shouldShowPackagingDetails"
        color="info"
        dense
        class="white--text"
    >
      {{ $t('pack_in') }}:
      {{ packagingId }}
      {{ packagingName }}
    </v-alert>
    <v-form @submit.prevent>
      <v-btn-toggle v-model="selectedPackageType"
                    v-if="!this.isPrePacked"
                    active-class="primary"
                    id='package-types'
                    class="mb-3"
                    style="width: 100%;">
        <v-row class="mt-0">
          <v-col cols="6"
                 sm="4"
                 class="pt-0"
                 v-for="packageType in packageTypes"
                 :key="packageType.id">
            <v-btn block
                   :id="'package_type_btn_' + packageType.id"
                   :value="packageType.id"
                   style="max-width: 100%;text-transform: none;"
            >
              <span>
                <v-icon class="mr-3">
                  {{ packageIcon(packageType.type) }}
                </v-icon>
                <br>
                <span>{{ translatePackaging(packageType.type) }}</span>
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-btn-toggle>
      <h2 class="font-weight-medium mb-4 text-center text-decoration-underline">
        {{ $t('scan_packing_identifier_barcode') }}
      </h2>
      <h2 class="text-center font-weight-light">
        {{ $t('or') }}
      </h2>
      <v-text-field
          id="packing_identifier_barcode_input"
          :label="$t('enter_packing_identifier_barcode')"
          v-model="packageIdentifier"
          :rules="[v => !!v]"
          :error-messages="errors"
      ></v-text-field>
      <div class="text-center" v-if="!startedPickFromBarcode">
        <v-btn
            id="packing_submit_and_next"
            color="secondary"
            @click.prevent="completePackingAndNextOrder"
            :disabled="!canSubmit"
            block
        >
          {{ $t('packing.submit_and_next') }}
        </v-btn>
      </div>
      <div class="text-center mt-2">
        <v-btn
            id="packing_submit_and_back"
            @click.prevent="completePackingAndBackHome"
            :disabled="!canSubmit"
            block
        >
          {{ $t('packing.submit_and_back') }}
        </v-btn>
      </div>
    </v-form>
    <v-overlay :value="submitLoading" :absolute="true" class="text-center">
      <v-progress-circular indeterminate/>
      <p>
        {{ $t('completing_order_picking') }}
      </p>
    </v-overlay>
  </div>
</template>

<script>

import {bus} from "@/bootstrap/bus";
import {
  ORDER_TYPE_FILTER_PRE_PACKED,
  ORDER_TYPE_FILTER_SINGLES,
} from '@/bootstrap/config';
import {REDIRECT_TO_NEXT_ORDER_ON_PICK_COMPLETE, REDIRECT_TO_ORDERS_ON_PICK_COMPLETE} from "../../bootstrap/config";

export default {
  name: "Packing",
  data: () => ({
    selectedPackageType: null,
    packageIdentifier: null,
    requestErrors: [],
    submitLoading: false
  }),
  components: {},
  computed: {
    isValidPackageIdentifier() {
      return !this.packageIdentifier || this.packageIdentifier.length === 12
    },
    packageTypes() {
      return this.$store.getters['picking/mappedPackageTypes']
    },
    packageIcon() {
      return (type) => {
        const icons = {
          "SMLETTER": 'mdi-email',
          "LGLETTER": 'mdi-email-multiple',
          "SMPACKET": 'mdi-inbox-outline',
          "SMPARCEL": 'mdi-package',
          "MDPARCEL": 'mdi-archive',
          "LGPARCEL": 'mdi-package-variant-closed',
        }
        return icons[type] ? icons[type] : null
      }
    },
    canSubmit() {
      return this.packageIdentifier &&
          this.isValidPackageIdentifier &&
          (
              this.isPrePacked ||
              this.selectedPackageType
          )
    },
    customerRequiresSpecialPackaging() {
      return this.$store.state.picking.order.specialPackagingRequiredByCustomer
    },
    itemRequiresSpecialPackaging() {
      return this.$store.getters['picking/itemRequiresSpecialPackaging']
    },
    isPrePacked() {
      return this.$store.state.picking.order.orderType === ORDER_TYPE_FILTER_PRE_PACKED
    },
    hasWarnings() {
      return this.customerRequiresSpecialPackaging ||
          this.itemRequiresSpecialPackaging
    },
    errors() {
      const errors = [...this.requestErrors]
      if(!this.isValidPackageIdentifier) {
        errors.push(this.$t('barcode_length_validation'))
      }
      return errors
    },
    shouldShowPackagingDetails () {
      if (
          this.selectedPackageType === this.$store.state.picking.order.preferredContainerTypeId &&
          this.$store.state.picking.order.orderType === ORDER_TYPE_FILTER_SINGLES &&
          this.packagingId &&
          this.packagingName
      ) {
        return true;
      } else {
        return false;
      }
    },
    packagingId () {
      let packagingId = this.$store.state.picking.order.packagingId;
      if (packagingId) {
        packagingId = packagingId.toString()
        while (packagingId.length < 2) {
          packagingId = "0" + packagingId;
        }
      }
      return packagingId;
    },
    packagingName () {
      return this.translatePackaging(
        this.$store.state.picking.order.packagingType
      );
    },
    startedPickFromBarcode () {
      return this.$store.state.picking.order.startPickFromBarcode
    }
  },
  created() {
    this.selectedPackageType = this.$store.state.picking.order.preferredContainerTypeId
    this.packageIdentifier = this.$store.state.picking.order.packageIdentifier
    bus.$on('barcodeScanned', this.handleBarcodeScan)
  },
  beforeDestroy() {
    bus.$off('barcodeScanned', this.handleBarcodeScan)
  },
  watch: {
    packageIdentifier() {
      this.requestErrors = []
    }
  },
  methods: {
    handleBarcodeScan(barcode) {
      this.packageIdentifier = barcode.trim()
    },
    translatePackaging(packagingType) {
      return this.$t(`package_types.${packagingType.toLowerCase()}`);
    },
    // Complete packing and redirect to the orders list
    async completePackingAndBackHome() {
      return this.completePacking(REDIRECT_TO_ORDERS_ON_PICK_COMPLETE);
    },
    // Complete packing and redirect to the next order, if available
    async completePackingAndNextOrder() {
      return this.completePacking(REDIRECT_TO_NEXT_ORDER_ON_PICK_COMPLETE);
    },
    async completePacking(redirectionStrategy) {
      this.requestErrors = []
      this.submitLoading = true
      try {
        const selectedPackageType = this.selectedPackageType
        const packageIdentifier = this.packageIdentifier
        await this.$store.dispatch('picking/complete', {selectedPackageType, packageIdentifier, redirectionStrategy})
      } catch (e) {
        this.submitLoading = false
        if (e?.response?.status === 422) {
          if (e.response.data.errors?.package_identifier) {
            this.requestErrors.push(e.response.data.errors?.package_identifier[0])
          } else {
            this.requestErrors.push(e.response.data.message)
          }
        } else {
          this.$store.dispatch('core/handleError', e)
        }
      }
      this.submitLoading = false
    }
  }
}
</script>
