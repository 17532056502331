import {api, fetchAccessToken} from '@/bootstrap/api'
import router from '@/bootstrap/router'
import moment from 'moment'
import setState from '@/utilities/store'
import {SESSION_TIMEOUT} from "@/bootstrap/config"
import auditLog from "@/bootstrap/auditLog";
import i18n from '@/plugins/i18n';

const defaultState = () => ({
    accessToken: null,
    accessTokenExpiresAt: null,
    hubId: null,
    authUser: null,
    errors: [],
    title: null,
    activeSessionTime: null,
    redirectToStartPickWithBarcode: false,
})


export default {
    state: defaultState(),
    getters: {
        getTitle(state) {
            return state.title ? state.title : i18n.t('perfect_picking')
        },
        getAuthUserId(state) {
            return state.authUser ? state.authUser.id : null
        },
        isAuthenticated(state) {
            return !!state.accessToken && !!state.authUser
        },
    },
    mutations: {
        setErrors(state, errors) {
            state.errors = errors
        },
        addError(state, error) {
            state.errors.push(error)
        },
        setAccessToken(state, token) {
            state.accessToken = token
            state.accessTokenExpiresAt = moment().add(token.expires_in, 'seconds')
        },
        setAuthUser(state, authUser) {
            state.authUser = authUser
        },
        setHubId(state, hubId) {
            state.hubId = hubId
        },
        setTitle(state, title) {
            state.title = title
        },
        resetState(state) {
            setState(state, defaultState())
        },
        setActiveSessionTime(state) {
            state.activeSessionTime = moment()
        },
        setRedirectToStartPickWithBarcode(state, redirect) {
            state.redirectToStartPickWithBarcode = redirect
        },
    },
    actions: {
        handleHubSelect({commit}, hubId) {
            commit('setHubId', hubId)
            commit('orders/resetState', null, {root: true})
        },
        resetErrorsIgnoringTimeout({commit}) {
            commit('setErrors', []);
        },
        resetErrors({commit, state}) {
            commit('setErrors', state.errors.filter(e => typeof e?.timeout !== 'undefined'))
            const interval = setInterval(() => {
                commit('setErrors', state.errors.map((e) => {
                    if (typeof e?.timeout !== 'undefined' && e.timeout > 0) {
                        e.timeout -= 1000;
                        return e;
                    }
                }).filter(e => e))
                if (state.errors.length === 0) {
                    clearInterval(interval);
                }
            }, 1000)
        },
        redirect({dispatch}, routeInfo) {
            const routeNamesDiffer = router.currentRoute.name !== routeInfo?.name
            const routeParamsDiffer = JSON.stringify(router.currentRoute?.params) !== JSON.stringify(routeInfo?.params)
            if (routeNamesDiffer || routeParamsDiffer) {
                dispatch('resetErrors')
                router.push(routeInfo)
            }
        },
        async redirectToOrders({dispatch, state}) {
            if (state.redirectToStartPickWithBarcode) {
                const hubId = state.hubId
                dispatch('redirect',
                  { name: 'start-pick-with-barcode', params: { hub_id: hubId} }
                )
            } else {
                dispatch('redirect', {name: 'orders'})
            }
        },
        handleError({commit, dispatch}, e) {
            if (typeof e.response !== 'undefined') {
                if (e.response.status === 401 || e.response.status === 419) {
                    dispatch('logout')
                }
            }
            commit('addError', e)
        },
        checkActiveSession({state, dispatch}) {
            if (
                state.activeSessionTime &&
                moment(state.activeSessionTime).add(SESSION_TIMEOUT, 'milliseconds') < moment()
            ) {
                dispatch('logout')
            }
        },
        async fetchAccessToken({commit}, {email, password}) {
            const token = await fetchAccessToken(email, password)
            if (token) {
                commit('setAccessToken', token)
            }
            return token
        },
        async logout({dispatch, commit, getters}) {
            await auditLog.commit()
            if(getters.isAuthenticated){
                api('logout', {handleError: false})
                commit('resetState')
                commit('orders/resetState', null, {root: true})
            }
            dispatch('redirect', {name: 'login'})
        },
        async getAuthUser({commit}) {
            const results = await api('authUser', {handleError: false})
            const authUser = results.data
            commit('setAuthUser', authUser)
        },
    },
    namespaced: true
}
