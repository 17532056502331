<template>
  <v-overlay
      :value="true"
      class="text-center"
      opacity="0.75"
  >
    <h1 class="mb-3 error-message">
      {{ message }}
    </h1>
    <v-btn
        id="item_mispick_error_close_btn"
        class="white--text"
        color="error"
        @click="$emit('close')"
    >
      {{ $t('close') }}
    </v-btn>
  </v-overlay>
</template>

<script>
export default {
  name: 'MispickChallenge',
  components: {},
  props: {
    message: {
      required: true,
      type: [String, Boolean]
    }
  },
  data: () => ({}),
}
</script>

<style scoped>
  .error-message{
    white-space: pre-wrap;
  }
</style>